import React from 'react';
import classnames from 'classnames/bind';
import styles from './styles.module.scss';
const cx = classnames.bind(styles);

const InitialsLogo = ({ color = 'white', strokeWidth = 2, size = 200, loader = false }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={`${size}px`}
    height={`${size - 40}px`}
    viewBox="0 0 225 155"
    className={cx(styles.svgContainer, { [styles.loader]: loader })}
  >
    <path
      id="MCCLogo"
      className={cx(styles.drawSVGPath, { [styles.loader]: loader })}
      fill="none"
      stroke={color}
      strokeWidth={strokeWidth}
      d="M 83.72,37.00
        C 83.72,37.00 95.28,24.00 95.28,24.00
          98.39,20.46 108.24,8.64 111.98,7.40
          120.38,4.61 115.74,20.88 114.54,24.00
          109.00,38.48 94.37,57.02 94.01,72.00
          92.62,86.88 100.75,80.88 98.75,87.62
          96.62,89.62 91.84,87.34 90.38,87.12
          84.88,83.00 86.40,76.37 86.92,70.00
          88.11,55.12 107.12,25.38 100.38,28.25
          100.38,28.25 71.64,62.00 71.64,62.00
          71.64,62.00 67.47,70.00 67.47,70.00
          65.52,73.31 60.65,78.36 56.74,74.40
          54.64,72.28 55.58,67.36 55.91,64.62
          55.91,64.62 60.88,41.50 60.88,41.50
          59.62,40.00 43.80,79.38 23.12,78.88
          17.25,80.12 10.65,78.66 8.43,76.91
          4.15,73.56 4.88,69.00 10.50,68.12
          13.00,76.00 22.92,70.56 29.00,67.37
          41.27,60.94 50.99,42.61 58.84,31.00
          60.85,28.01 67.30,16.84 71.61,18.62
          73.91,19.58 73.93,22.92 73.98,25.00
          74.17,32.13 72.84,34.55 70.42,41.00
          70.42,41.00 65.00,58.00 65.00,58.00
          65.00,58.00 83.72,37.00 83.72,37.00 Z
        M 149.00,70.79
        C 138.89,76.89 117.63,87.29 110.32,72.00
          107.31,65.71 112.81,51.89 115.75,46.00
          121.65,34.21 135.55,11.73 148.00,6.99
          151.78,5.55 156.62,4.50 162.62,6.50
          169.38,10.25 157.50,35.50 149.00,32.00
          145.38,27.25 158.25,16.50 154.50,12.25
          149.00,11.25 146.66,16.52 142.25,20.62
          134.12,28.75 124.97,41.10 121.45,52.00
          120.27,56.24 116.68,65.81 121.45,68.91
          124.09,71.01 129.07,69.79 132.00,68.91
          140.38,67.12 151.13,55.64 157.88,60.38
          159.47,50.21 167.29,42.83 176.00,37.47
          178.87,35.70 184.29,33.00 187.69,34.60
          190.25,35.81 193.00,44.88 185.50,47.75
          181.12,46.88 186.25,43.25 183.62,41.50
          178.50,40.38 172.75,51.62 170.00,55.12
          163.88,65.50 166.50,70.88 168.75,72.38
          177.12,73.25 177.98,71.17 181.00,70.28
          187.89,68.25 195.25,65.38 198.00,67.00
          199.00,70.12 197.34,70.73 195.57,72.61
          190.23,78.29 169.70,83.21 162.11,77.35
          157.09,73.47 158.38,66.38 156.88,65.88
          155.12,65.75 153.27,68.22 149.00,70.79 Z
        M 214.88,69.75
        C 215.80,69.97 216.99,70.50 217.85,70.89
          227.77,75.37 212.88,85.00 209.16,76.85
          208.75,74.50 207.75,74.62 210.25,70.38
          210.25,70.38 214.88,69.75 214.88,69.75 Z
        M 36.00,101.97
        C 47.86,105.74 73.94,111.86 86.00,112.00
          90.31,112.05 99.20,112.34 103.00,111.20
          103.00,111.20 115.00,105.31 115.00,105.31
          115.00,105.31 137.00,97.16 137.00,97.16
          159.29,91.09 176.43,91.96 199.00,92.00
          201.67,92.00 204.38,91.92 207.00,92.53
          215.62,94.51 223.73,100.80 217.15,109.91
          215.94,111.58 214.69,112.69 212.96,113.79
          209.52,115.98 204.97,117.19 201.00,118.00
          176.95,122.91 163.88,122.04 140.00,122.00
          140.00,122.00 114.12,118.75 101.62,119.62
          109.00,118.12 85.62,121.62 91.12,125.88
          91.12,125.88 105.12,130.25 105.12,130.25
          105.12,130.25 124.25,136.00 124.25,136.00
          124.25,136.00 137.38,140.00 137.38,140.00
          140.44,140.91 150.78,141.44 152.00,143.88
          153.38,146.62 151.25,147.81 149.25,147.88
          146.13,147.98 139.42,147.62 136.25,146.62
          136.25,146.62 122.50,142.62 122.50,142.62
          122.50,142.62 103.00,135.62 103.00,135.62
          89.00,131.00 92.12,131.75 82.00,130.39
          76.26,130.02 69.25,134.40 64.00,136.55
          55.18,140.15 39.39,144.89 30.00,145.00
          25.93,145.04 13.31,145.64 10.33,143.83
          5.74,141.03 4.23,131.22 8.31,127.72
          12.18,124.41 14.62,118.50 60.88,122.38
          60.88,122.38 79.00,122.38 79.00,122.38
          78.38,122.88 90.88,120.12 84.88,118.00
          84.88,118.00 80.00,118.00 80.00,118.00
          68.22,117.98 65.53,116.02 55.00,113.66
          44.49,111.31 34.62,109.25 24.12,104.12
          12.25,100.12 4.38,99.12 6.00,94.00
          12.00,90.62 21.92,97.50 36.00,101.97 Z
        M 185.12,98.38
        C 171.50,98.62 159.62,98.38 131.00,106.46
          125.57,108.40 111.50,112.00 116.38,113.38
          116.38,113.38 146.00,116.00 146.00,116.00
          146.00,116.00 169.00,116.00 169.00,116.00
          185.38,114.12 183.75,114.88 193.25,113.00
          207.88,109.75 211.88,109.38 211.88,102.25
          205.62,97.50 198.88,98.62 185.12,98.38 Z
        M 36.25,129.25
        C 29.12,129.00 13.62,130.38 12.38,133.88
          10.75,136.38 14.46,139.18 14.62,138.12
          17.88,138.62 24.62,139.25 30.62,138.25
          40.35,138.20 63.88,131.00 56.50,129.00
          56.50,129.00 36.25,129.25 36.25,129.25 Z
        M 118.00,114.38M 172.25,66.50M 177.62,109.12M 27.75,134.12M 49.38,116.62"
    />
  </svg>
);

export default InitialsLogo;
